import * as React from "react"
import APIKeyPage from "../components/apiKey/pages/APIKeyPage"
import SharedHeader from "../components/general/components/SharedHeader"


// markup
const APIKey = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <APIKeyPage />
    </React.Fragment>
  )
}

export default APIKey
