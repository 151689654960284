import { useLocation } from "@reach/router";
import { Button, Modal, Spin } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { loginSuccessful } from "../../../redux/actions/user-actions";
// markup
const MessageModal = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState({});
  const accessKey = get(props.user, "accessKey");

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handleOk() {
    if (props.onOk) {
      props.onOk();
    }
  }

  useEffect(() => {
    if (props.data) {
    }
  }, [props.data]);

  return (
    <React.Fragment>
      <Modal
        wrapClassName="no-padding-modal-body modal-body-background-transparent"
        centered
        maskClosable
        width="60%"
        mask
        footer={null}
        className=""
        visible={visible}
        closable={false}
      >
        <Spin spinning={props.loading}>
          <div className="lightgreybox-bg-color rounded-3xl px-5 py-12">
            {/* <div className='inline-block text-lg font-semibold mb-2 flex justify-center '>
                首次绑定 API 免费礼物
              </div> */}
            <div className="inline-block text-lg font-semibold mb-10 flex justify-center ">
              {t("freeGift", sourceKey.api)}
            </div>

            <div className="my-3 flex justify-start">
              <span className="inline-block ">
                {/* 感谢您与我们绑定您的第一个 API! 作为奖励，我们将免费送您 7 个有效天数和 15 VSDT。
                  <br />  <br /> */}
                {t("thanksReward", sourceKey.api)}
              </span>
            </div>

            <div className="mt-10">
              <Button
                block
                className="main-button-color input-border"
                size="large"
                onClick={(e) => {
                  handleOk();
                }}
              >
                {t("confirm")}
              </Button>
            </div>
          </div>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
