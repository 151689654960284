

import { message } from "antd";
import { navigate } from "gatsby";
import { get } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { routes } from "../../../route";



// markup
const RequireAuthWrapper = (props) => {


    useEffect(() => {
        const isAuthenticated = get(props, 'user.isAuthenticated');

        // if (!isAuthenticated && typeof window !== `undefined`) {
        //     navigate(routes.login.to(), {
        //         state : {
        //             hideBack : true,
        //         }
        //     });
        //     message.error('Please Login to proceed');
        //     return <></>;
        // }

        if(props.onChecked){
            props.onChecked(isAuthenticated);
        }

    }, [])


    return (
        <React.Fragment >
            {
                props.children
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.user
});


const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(RequireAuthWrapper)
