import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Button, Collapse, Form, Input, Skeleton, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby-link";
import { filter, isEmpty, map } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import bindApiKey from "../../../newApi/profile/bindApiKey";
import getOrCreateRobotProfile from "../../../newApi/profile/getOrCreateRobotProfile";
import getProfiles from "../../../newApi/profile/getProfiles";
import {
  loginSuccessful,
  updateUser,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import ConfirmationModal from "../../general/components/ConfirmationModal";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import RequireAuthWrapper from "../../general/components/RequireAuthWrapper";
import TutorialVideoModal from "../../general/components/TutorialVideoModal";
import APIAgreementModal from "../components/APIAgreementModal";
import FreeGiftModal from "../components/FreeGiftModal";

const { Panel } = Collapse;
const referLink = {
  binance: "https://accounts.binance.com/en/register?ref=LU22814A",
  ftx: "https://ftx.com/referrals?gclid=CjwKCAjwqJSaBhBUEiwAg5W9pwIrfqP_5DkqopJ11r6AteYpFLeUndnwlEU4vQLc_j21Ra3rIY7UnxoC0_kQAvD_BwE#a=148874955",
};
// markup
const APIKeyPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [arrayLoading, setArrayLoading] = useState(true);
  const [agreementVisible, setAgreementVisible] = useState(false);
  const [freeGiftVisible, setFreeGiftVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [profile, setProfile] = useState({});
  const [isNewBind, setIsNewBind] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [robotProfilesId, setRobotProfilesId] = useState("");
  const [robotProfile, setRobotProfile] = useState({});
  const [platform, setPlatform] = useState(false);
  const [failIp, setFailIp] = useState({});

  const accessKey = get(props.user, "accessKey");
  const user = get(props.user, "user");
  const product = get(props, "product");

  // console.log(props);
  useEffect(() => {
    let query = queryString.parse(location.search);
    let platform = get(query, "platform");
    if (platform === "binance") {
      setVideoShow(true);
    }
    setPlatform(get(query, "platform"));
    getData();
  }, []);

  useEffect(() => {
    if (!isEmpty(profile)) {
      let robotProfiles = get(profile, "robotProfiles");
      // robotProfiles = filter(robotProfiles,(item,index)=>{
      //   return get(item,"exchange") === get(product,"apiKeyActiveId");
      // })
      // robotProfiles = robotProfiles[0];
      // console.log("bbb",get(profile, "bindedAPi"));
      if (get(profile, "bindedAPi") === 1) {
      } else {
        setIsNewBind(true);
      }
      getOrCreateRobotProfile({
        usedFor: platform,
        exchange: platform,
        // profileId: get(profile, "_id"),/
      })
        .then((res) => {
          let data = get(res, "data");
          setRobotProfilesId(
            get(data, "robotProfileId")
              ? get(data, "robotProfileId")
              : get(data, "_id")
          );
        })
        .catch((err) => {
          console.log(err);
          message.error(err.message);
        });
    }
  }, [profile]);

  // useEffect(() => {
  //   if (!isEmpty(robotProfilesId)) {
  //     getRobotProfiles({
  //       _id: robotProfilesId,
  //     })
  //       .then((res) => {
  //         let data = get(res, "data");
  //         data = data[0];
  //         setRobotProfile(data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         message.error(err.message);
  //       });
  //   }
  // }, [robotProfilesId]);

  // console.log("robotID", robotProfilesId);

  function getData() {
    setArrayLoading(true);
    getProfiles(1, 0, {
      // _id: get(props.user, "user.profile._id")
    })
      .then((res) => {
        setProfile(get(res, "data[0]"));
        setArrayLoading(false);
      })
      .catch((err) => {
        setArrayLoading(false);
        message.error(err.message);
      });
  }
  function handleSubmit() {
    // const hide = message.loading("正在绑定API... Binding API...");
    setLoading(true);
    const hide = message.loading(t("bindingApi", sourceKey.api));
    form
      .validateFields()
      .then((values) => {
        let extraData = {
          passphrase: values?.passphrase || "",
        };

        let data = {
          exchange: get(product, "apiKeyActiveId"),
          apiKey: get(values, "apiKey"),
          secretKey: get(values, "secretKey"),
          robotProfileId: robotProfilesId,
          exchangeExtraData: extraData,
          // bindTime: new Date(),
          // apiBindedDay: 1,
        };

        bindApiKey({
          _id: get(profile, "_id"),
          // api: [data],
          ...data,
        })
          .then((res) => {
            // console.log(res);
            let data = res?.data;
            if (data?.isAuthenticated === 0) {
              if (!isEmpty(data?.failed)) {
                setFailIp({
                  ...failIp,
                  visible: true,
                  data: data?.failed || [],
                });
              } else {
                message.error(t("invalidApiKey", sourceKey.api));
              }
              setLoading(false);
              return;
            }
            let profileData = get(res, "data");
            setProfile(profileData);
            props.updateUser(
              {
                profile: {
                  ...profileData,
                },
              } || {}
            );
            // message.success("成功绑定 API. Sucessfully Binded API");
            message.success(t("bindSuccess", sourceKey.api));

            // setTimeout(hide, 1);
            if (isNewBind && profile?.enableProfitShare !== 1) {
              //no activation code transaction with the name of newUser under this profileId.
              //Means eligible to get new user free gift
              setFreeGiftVisible(true);
            } else {
              navigate(routes.apiKeyListing.to());
            }
            setLoading(false);
          })
          .catch((err) => {
            message.error(t("wentWrong", sourceKey.home), 3);
            // console.log(err);
            setLoading(false);
          });
        // })
        // .catch((err) => {
        //   message.error(err.message);
        //   setTimeout(hide, 1);
        //   setLoading(false);
        // });
        //   } else {
        //     let checkData = get(res1, "data.data");
        //     if (checkData?.failed) {
        //       setFailIp({
        //         ...failIp,
        //         visible: true,
        //         data: checkData?.failed || [],
        //       });
        //     } else {
        //       message.error(t("invalidApiKey", sourceKey.api));
        //     }
        //     setTimeout(hide, 1);
        //     setLoading(false);
        //   }
        // })
        // .catch((err) => {
        //   console.log(err);
        //   // message.error(err.message);
        //   message.error(t("invalidApiKey", sourceKey.api));
        //   setTimeout(hide, 1);
        //   setLoading(false);
        // });
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
        });
        setTimeout(hide, 1);
        setLoading(false);
      });
  }

  const handleFreeGiftConfirm = () => {
    setLoading(true);
    const hide = message.loading(t("saving", sourceKey.api));
    let newUserGiftObj = {
      profileId: get(user, "profile._id"),
      amount: 50,
      sourceType: 1,
      amountType: 1,
      trxType: "IN",
    };
    feathers("profile-transactions", accessKey)
      .create(newUserGiftObj)
      .then((res) => {
        message.success(t("crediting", sourceKey.api));
        // navigate(routes.asset.to(), { replace: true });
        navigate(routes.apiKeyListing.to());
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setTimeout(hide, 1);
      });
  };

  const handleOnBack = () => {
    navigate(routes.apiKeyListing.to());
  };

  function CopyIPText({ text }) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };
    return (
      <div>
        {text} {"  "}
        <CopyToClipboard text={text}>
          <button
            onClick={handleCopy}
            style={{ color: "white", fontSize: "8px" }}
          >
            {copied ? "COPIED!" : "COPY"}
          </button>
        </CopyToClipboard>
      </div>
    );
  }
  // const exchangeApi = find(get(profile, "robotProfiles"), [
  //   "exchange",
  //   get(product, "apiKeyActiveId"),
  // ]);
  // const apiKey = get(robotProfile, "apiKey");
  // const secretKey = get(robotProfile, "secretKey");
  let robotProfiles = filter(profile?.robotProfiles || [], (item, index) => {
    return item?.exchange === platform;
  });
  robotProfiles = robotProfiles[0];
  let extraData = {};
  if (platform === "bitget") {
    // extraData = get(robotProfile, "exchangeExtraData") || {};
  }

  // const isBinded = apiKey && secretKey;
  const isBinded = robotProfiles?.apiBindedDay >= 1;
  // console.log(robotProfiles, isBinded);
  return (
    <React.Fragment>
      <RequireAuthWrapper />
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* 授权{" "}
                <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery> */}
                API
              </div>
            }
            showBack={true}
            onBack={handleOnBack}
          >
            <div className="px-5">
              <div className="flex justify-start items-center px-2">
                <span className=" font-semibold text-lg">
                  {" "}
                  {/* 绑定您的 API 与您的币安账户
                  <br /> */}
                  {t("bindYourApi", sourceKey.api)} {t(platform, sourceKey.api)}{" "}
                  {t("account", sourceKey.api)}
                </span>
              </div>
              {/* <div className="flex justify-start items-center text-lg mt-10">
                未有户口？ 请点击{" "}
                <a
                  className="underline decoration-sky-500 text-blue-600"
                  href="https://accounts.binance.com/en/register?ref=LU22814A"
                  target="_blank"
                >
                  这里
                </a>
              </div> */}
              {/* {platform === "binance" ?( */}
              <div className="inline-block text-lg px-2">
                {t("noAccount", sourceKey.api)}&nbsp;
                <a
                  className="underline decoration-sky-500 text-blue-600"
                  href={get(referLink, platform)}
                  target="_blank"
                >
                  {t("here", sourceKey.api)}
                </a>
              </div>
              {/* ) */}
              {/* :""
            } */}

              <div className="px-2">
                <Form form={form}>
                  <div className="mt-2 ">API KEY :</div>
                  <Form.Item
                    name="apiKey"
                    autoComplete="new-user"
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写 API Key
                            <br /> */}
                            {t("apiKeyRequired", sourceKey.api)}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (_, value) => {
                          form.setFieldsValue({
                            apiKey: value?.trim(),
                          });
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("insertApiKey", sourceKey.api)}
                      className={"input-border"}
                      disabled={isBinded && !isEdit}
                    />
                  </Form.Item>

                  <div className="">SECRET KEY :</div>
                  <Form.Item
                    name="secretKey"
                    autoComplete="new-user"
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写 Secret Key
                            <br /> */}
                            {t("secretKeyRequired", sourceKey.api)}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (_, value) => {
                          form.setFieldsValue({
                            secretKey: value?.trim(),
                          });
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("insertSecretKey", sourceKey.api)}
                      className={"input-border"}
                      disabled={isBinded && !isEdit}
                    />
                  </Form.Item>

                  {platform === "bitget" && (
                    <>
                      <div className="mt-2 ">
                        {t("passphrase", sourceKey.api)} :
                      </div>
                      <Form.Item
                        name="passphrase"
                        rules={[
                          {
                            required: true,
                            message: (
                              <React.Fragment>
                                {t("passphraseRequired", sourceKey.api)}
                              </React.Fragment>
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("passphrase", sourceKey.api)}
                          className={"input-border"}
                          disabled={isBinded && !isEdit}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form>
              </div>

              {arrayLoading ? (
                <Skeleton.Button active></Skeleton.Button>
              ) : (
                <div className="grid grid-cols-4 gap-2 px-2">
                  <div
                    className="col-span-4"
                    style={{
                      color: "red",
                    }}
                  >
                    <div>
                      <span>{t("whiteListDesc", sourceKey.api)}:</span>
                    </div>
                    {/* <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="190.92.213.39"/>
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="159.138.90.96"/>
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="190.92.221.83"/>
                    </div> */}
                    <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="198.13.50.87" />
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="45.32.48.23" />
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="45.76.48.52" />
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      <CopyIPText text="45.76.205.109" />
                    </div>

                    {/* <div className="flex justify-start items-center font-semibold">
                      190.92.213.39
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      159.138.90.96
                    </div>
                    <div className="flex justify-start items-center font-semibold">
                      190.92.221.83
                    </div> */}
                    <br />
                  </div>
                  <div className="col-span-3">
                    {isBinded ? (
                      <>
                        <div className="flex justify-start items-center ">
                          {arrayLoading ? (
                            <Skeleton.Button active></Skeleton.Button>
                          ) : (
                            t("bindedApi", sourceKey.api)
                          )}
                          {arrayLoading ? (
                            ""
                          ) : isBinded ? (
                            <CheckCircleFilled
                              className="ml-3"
                              style={{ color: "green", fontSize: 20 }}
                            />
                          ) : (
                            <CloseCircleFilled
                              className="ml-3"
                              style={{ color: "red", fontSize: 20 }}
                            />
                          )}
                        </div>
                        {/* <div>
                          <span className=" font-semibold">API Key</span>
                        </div>
                        <div className="flex justify-start items-center">
                          {apiKey.replace(
                            apiKey.substring(5, apiKey.length - 5),
                            "***********"
                          )}
                        </div>
                        <div>
                          <span className=" font-semibold">Secret Key</span>
                        </div>
                        <div className="flex justify-start items-center">
                          {secretKey.replace(
                            secretKey.substring(5, secretKey.length - 5),
                            "***********"
                          )}
                        </div> */}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              <div className="flex justify-center items-center mt-5 px-2">
                {isBinded ? (
                  <Button
                    className="main-button-color button-padding"
                    shape="round"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      if (isEdit) {
                        form
                          .validateFields()
                          .then((values) => {
                            setAgreementVisible(true);
                          })
                          .catch((err) => {});
                      } else {
                        // form.setFieldsValue({
                        //   apiKey: apiKey,
                        //   secretKey: secretKey,
                        // });
                        setIsEdit(true);
                      }
                    }}
                  >
                    {isEdit
                      ? t("save", sourceKey.api)
                      : t("edit", sourceKey.api)}
                  </Button>
                ) : (
                  <Button
                    className="main-button-color button-padding"
                    shape="round"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      form
                        .validateFields()
                        .then((res) => {
                          setAgreementVisible(true);
                        })
                        .catch((err) => {});
                    }}
                  >
                    {t("submit")}
                  </Button>
                )}
              </div>
              {/* <div>
                <strong>如何绑定 API？</strong>

                <div className="ml-7 mt-3">
                  <ol class="list-decimal">
                    <li>
                      请前往
                      <a
                        className="underline decoration-sky-500 text-blue-600"
                        href="https://accounts.binance.com/en/register?ref=LU22814A"
                        target="_blank"
                      >
                        binance.com
                      </a>
                      登录或创建您的户口。
                    </li>
                    <li>点击API管理</li>
                    <li>给API密钥一个标签然后创建您的API。</li>
                    <li>
                      当API已创建后， 请点击“编辑权限” 然后选择此权限:
                      <ul className="list-disc ml-5">
                        <li>允许现货及杠杆交易</li>
                      </ul>
                    </li>

                    <li>保存此更改。</li>
                    <li>粘贴API Key 和 Secret Key然后复制在我们的应用程序。</li>
                    <li>点击 “Submit”。</li>
                  </ol>
                </div>

                <div>
                  <strong>若您的API无效，请确保您有完成规定如下：</strong>

                  <div className="ml-7 mt-3">
                    <ol class="list-decimal">
                      <li>
                        请确保在币安应允此权限：
                        <ul className="list-disc ml-5">
                          <li>允许现货及杠杆交易</li>
                        </ul>
                      </li>
                      <li>注意，一个币安户口只能绑定一个应用程序。</li>
                      <li>
                        {" "}
                        若您还没有绑定我们的专用IP地址，您的户口将在90天后自动停止绑定。请重新绑定您的API。
                      </li>
                    </ol>
                  </div>
                </div>
              </div> */}

              <br />

              {platform === "binance" ? (
                <>
                  <div>
                    <Collapse
                      className=""
                      ghost
                      expandIconPosition="right"
                      style={{}}
                    >
                      <Panel
                        header={
                          <strong className="">
                            {t("howBind", sourceKey.api)}
                          </strong>
                        }
                        style={{
                          color: "white",
                          fontSize: 10,
                        }}
                      >
                        <div className="ml-7 mt-1 text-white">
                          <ol class="list-decimal">
                            <li>
                              {t("goto", sourceKey.api)}
                              <a
                                className="underline decoration-sky-500 text-blue-600"
                                href="https://accounts.binance.com/en/register?ref=LU22814A"
                                target="_blank"
                              >
                                {" "}
                                {t("binanceCom", sourceKey.api)}{" "}
                              </a>
                              {t("apiDesc1", sourceKey.api)}
                            </li>
                            <li>{t("apiDesc2", sourceKey.api)}</li>
                            <li>{t("apiDesc3", sourceKey.api)}</li>
                            <li>
                              {t("apiDesc4", sourceKey.api)}
                              <ul className="list-disc ml-5">
                                <li>{t("enableSpotMargin", sourceKey.api)}</li>
                              </ul>
                            </li>
                            <li>{t("saveEdited", sourceKey.api)}</li>
                            <li>{t("copyApi", sourceKey.api)}</li>
                            <li>{t("clickSubmit", sourceKey.api)}</li>
                          </ol>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div>
                    <Collapse
                      className=""
                      ghost
                      expandIconPosition="right"
                      style={
                        {
                          // color: "white",
                        }
                      }
                    >
                      <Panel
                        header={
                          <strong className="">
                            {t("fulfill", sourceKey.api)}
                          </strong>
                        }
                        style={{
                          color: "white",
                          fontSize: 10,
                        }}
                      >
                        <div className="ml-7 mt-3 text-white">
                          <ol class="list-decimal">
                            <li>
                              {t("allowRestriction", sourceKey.api)}
                              <ul className="list-disc ml-5">
                                <li>{t("enableSpotMargin", sourceKey.api)}</li>
                              </ul>
                            </li>
                            <li>{t("eachBinanceAccount", sourceKey.api)}</li>
                            <li> {t("boundApi", sourceKey.api)}</li>
                          </ol>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </NavHeader>
        </Spin>
      </Layout>

      <TutorialVideoModal
        type="api"
        visible={videoShow}
        onClose={() => {
          setVideoShow(false);
        }}
      />

      <APIAgreementModal
        visible={agreementVisible}
        onClose={() => {
          setAgreementVisible(false);
        }}
        onOk={() => {
          setAgreementVisible(false);
          handleSubmit();
        }}
      ></APIAgreementModal>

      <FreeGiftModal
        visible={freeGiftVisible}
        loading={loading}
        onClose={() => {
          setFreeGiftVisible(false);
        }}
        onOk={() => {
          setFreeGiftVisible(false);
          handleFreeGiftConfirm();
        }}
      ></FreeGiftModal>

      <ConfirmationModal
        visible={failIp?.visible}
        onClose={() => {
          setFailIp({
            ...failIp,
            visible: false,
            data: [],
          });
        }}
        confirmButton={(handleOk) => {
          return <div className="flex justify-center items-center mt-10"></div>;
        }}
      >
        <div>{t("apiKeyFail", sourceKey.api)}</div>
        <div className="mt-2">
          {map(failIp?.data || [], (item, index) => {
            return <div>- {item}</div>;
          })}
        </div>
        <div className="mt-4">{t("orDetails", sourceKey.api)}</div>
      </ConfirmationModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(APIKeyPage);
