import { ArrowLeftOutlined } from "@ant-design/icons";
import { Modal, Steps } from "antd";
import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import MediaQuery from "react-responsive";
import { congraImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";

const skipVideoStyle = { borderColor: "white", color: "white", width: 102 };

const TutorialVideoModal = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [lagType, setLagType] = useState("eng");
  const [current, setCurrent] = useState(0);
  const [ready, setReady] = useState(false);
  const [type, setType] = useState("");

  // const chsVideoLink =
  //   "https://www.youtube.com/watch?v=90lh-4LNDSE&ab_channel=EzSight";
  // const engVideoLink =
  //   "https://www.youtube.com/watch?v=y_xaqjM-y60&feature=youtu.be&ab_channel=EzSight";

  const chsVideoLink =
    type === "register"
      ? "https://www.youtube.com/watch?v=90lh-4LNDSE&ab_channel=EzSight"
      : "https://www.youtube.com/watch?v=UNAKhXZetd4&ab_channel=MyITS";
  const engVideoLink =
    type === "register"
      ? "https://www.youtube.com/watch?v=y_xaqjM-y60&feature=youtu.be&ab_channel=EzSight"
      : "https://www.youtube.com/watch?v=-48yJY9Y-Ro&ab_channel=MyITS";

  // const chsVideoLink =
  //   type === "register"
  //     ? "https://drive.google.com/file/d/1L3BrPRiNusmbOQHAY3jt0G_kjRf99iOg/preview"
  //     : "";
  // const engVideoLink =
  //   type === "register"
  //     ? "https://drive.google.com/file/d/1mFOZsY9GQz63lAJYU3sCJEBIEFNKLiFd/preview"
  //     : "";

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  useEffect(() => {
    setType(props.type);
  }, [props.type]);

  function close() {
    // setForceStop(0);
    if (props.onClose) {
      props.onClose();
      setCurrent(0);
      setLagType("eng");
      setReady(false);
    } else {
      setVisible(false);
    }
  }

  const _renderContent = () => {
    const _languageButton = () => {
      const languages = [
        {
          text: t("english", sourceKey.api),
          value: "eng",
        },
        {
          text: t("chinese", sourceKey.api),
          value: "chs",
        },
      ];
      return (
        <div className="flex justify-center items-center my-4">
          {map(languages, (item, index) => {
            return (
              <div
                className={`p-4 px-8 rounded-full text-center cursor-pointer font-semibold m-2 text-base ${
                  lagType === item.value
                    ? "bg-main-color-gradient"
                    : "box-bg-color"
                } `}
                onClick={() => {
                  setLagType(get(item, "value"));
                }}
              >
                {get(item, "text")}
                {/* <Button
                  style={{
                    borderColor: `${
                      get(item, "value") === lagType ? "" : "#"
                    }`,
                    borderRadius: 16,
                    width: 130,
                    color: `${get(item, "value") === lagType ? "" : "#"}`,
                  }}
                  className={`${
                    get(item, "value") === lagType ? "main-button-color" : "second-button-color"
                  }`}
                  onClick={() => {
                    setLagType(get(item, "value"));
                  }}
                >
                  {get(item, "text")}
                </Button> */}
              </div>
            );
          })}
        </div>
      );
    };

    const ChooseLanguage = () => {
      return (
        <>
          {/* <div className="flex justify-center mt-8">请选择您视频的语言。</div> */}
          <div className="flex justify-center">
            {t("chooseLanguage", sourceKey.api)}
          </div>
          {_languageButton()}
          <div className="flex justify-center mt-10 m-4">
            <span
              className="px-3 py-1.5 border rounded-md cursor-pointer mr-5 text-center rounded-lg"
              style={skipVideoStyle}
              onClick={close}
            >
              {t("skipVideo", sourceKey.api)}
            </span>
            <span
              className="rounded-md bg-main-color-gradient px-3 py-1.5 cursor-pointer text-center rounded-lg"
              onClick={() => {
                //   handelSubmit(getType);
                // setVideoVisible(true);
                // setVisible(false);
                setCurrent(current + 1);
              }}
              style={{ width: 102 }}
            >
              {t("next", sourceKey.api)}
            </span>
          </div>
        </>
      );
    };

    const PlayVideo = () => {
      return (
        <>
          {/* <div className="flex justify-center mt-5">Registration Video</div> */}
          <div>
            <ReactPlayer
              url={lagType === "eng" ? engVideoLink : chsVideoLink}
              controls={true}
              width="100%"
              onReady={() => {
                setReady(true);
              }}
              style={{
                borderRadius: 10,
              }}
            />

            {/* <iframe
              src={lagType === "eng" ? engVideoLink : chsVideoLink}
              width="100%"
              height="480"
              allowFullScreen
              //   frameBorder="0"
            ></iframe> */}
          </div>

          <div className="flex justify-end mt-10 m-4">
            {ready === true && (
              <>
                <span
                  className="px-3 py-1.5 border rounded-md cursor-pointer mr-5 text-center rounded-lg"
                  style={skipVideoStyle}
                  onClick={close}
                >
                  {t("skipVideo", sourceKey.api)}
                </span>
                <span
                  className="rounded-md bg-main-color-gradient px-3 py-1.5 cursor-pointer text-center rounded-lg"
                  onClick={() => {
                    setCurrent(current + 1);
                  }}
                  style={{ width: 102 }}
                >
                  {t("next", sourceKey.api)}
                </span>
              </>
            )}
          </div>
        </>
      );
    };

    const FinishGuide = () => {
      return (
        <>
          <div className="flex justify-center mt-8">
            <img src={congraImg} style={{ width: 60, height: 60 }} />
          </div>
          {/* <div className="flex justify-center mt-8">
            观看完毕。请开始{type === "register" ? "创建用户" : "连接API"}。
          </div> */}
          <div className="flex justify-center">
            {t("startTo", sourceKey.api)}{" "}
            {type === "register"
              ? t("createOwn", sourceKey.api)
              : t("connectAPi", sourceKey.api)}
            !
          </div>
          <div className="flex justify-center mt-10 m-4">
            <span
              className="rounded-md bg-main-color-gradient px-3 py-1.5 cursor-pointer text-center rounded-lg"
              onClick={close}
              style={{ width: 102 }}
            >
              {t("close", sourceKey.api)}
            </span>
          </div>
        </>
      );
    };
    const steps = [
      {
        title: () => {
          return (
            <div className="text-white">
              {t("selectLanguage", sourceKey.api)}
            </div>
          );
        },
        render: () => {
          return <ChooseLanguage />;
        },
      },
      {
        // title: "Video",
        title: () => {
          return <div className="text-white">{t("video", sourceKey.api)}</div>;
        },
        render: () => {
          return <PlayVideo />;
        },
      },
      {
        // title: "Finish",
        title: () => {
          return <div className="text-white">{t("finish", sourceKey.api)}</div>;
        },
        render: () => {
          return <FinishGuide />;
        },
      },
    ];

    return (
      <div
        className="lightgreybox-bg-color rounded-3xl pb-10 pt-5 pr-5 pl-5"
        style={{ borderRadius: 15 }}
      >
        {current !== 0 && (
          <div>
            <ArrowLeftOutlined
              onClick={() => {
                setCurrent(current - 1);
              }}
            />
          </div>
        )}

        <div className="text-xl font-sm mt-3 mb-3">
          <div className="flex justify-center">
            {t("videoTutorials", sourceKey.api)}
          </div>
        </div>
        <Steps current={current}>
          {steps.map((item, index) => (
            <Steps.Step key={index} title={item.title()} />
          ))}
        </Steps>

        <br />
        {steps[current].render()}
      </div>
    );
  };

  return (
    <React.Fragment>
      <MediaQuery maxWidth={670}>
        <Modal
          className="no-padding-modal-body modal-body-background-transparent"
          centered
          maskClosable={false}
          width="100%"
          mask
          footer={null}
          closable={false}
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          {_renderContent()}
        </Modal>
      </MediaQuery>
      <MediaQuery minWidth={671}>
        <Modal
          className="no-padding-modal-body modal-body-background-transparent"
          centered
          maskClosable={false}
          width="60%"
          mask
          footer={null}
          closable={false}
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          {_renderContent()}
        </Modal>
      </MediaQuery>
    </React.Fragment>
  );
};

export default TutorialVideoModal;
